import { CONTENT_TYPES } from 'services/contentful/constants'

export const BLOG_ROUTE = '/about/blog'
export const CAREERS_ROUTE = 'https://careers.achieve.com'
export const CONSUMER_NAME_ROUTE = '/dollars-sense'
export const GET_STARTED_ROUTE = '/get-started?brand=achieve&section=achieve_web'
export const GET_STARTED_ROUTE_HL = '/get-started?brand=achieve&section=achieve_hl'
export const LEARN_ROUTE = '/learn'
export const LICENSE_ROUTE = '/licenses'
export const MEMBER_STORIES_ROUTE = '/about/member-stories'
export const PRESS_ROUTE = '/about/press'
export const SIGN_IN_ROUTE = '/signin'
export const ROUTE_EXCEPT_NOFOLLOW = ['achieve.com', 'dev.ffngcp.com', 'stg.ffngcp.com']
export const ROUTE_ASSET_EXCEPT = 'assets.ctfassets.net'
export const CONTACT_US_ROUTE = '/about/contact-us'
export const EMPLOYEE_STORIES_ROUTE = '/about/employee-stories'
export const MOLO_ROUTE = '/molo-app'
export const PARTNERS_ROUTE = '/about/partners'
export const GOOD_ROUTE = '/good-app'
export const DEBT_CONSOLIDATION = '/debt-consolidation'
export const AR_HOW_ROUTE = '/resolve-debt/how-achieve-resolution-works'
export const AR_WHO_ROUTE = '/resolve-debt/who-achieve-resolution-helps'
export const AR_WHY_ROUTE = '/resolve-debt/why-choose-achieve-resolution'
export const AR_DISCLAIMERS_ROUTE = '/resolve-debt/disclaimers'
export const DEBT_INCOME_RATIO_ROUTE = '/tools/debt-income-ratio-calculator'
export const ACCELERATION_LOAN_ROUTE = '/acceleration-loan'
export const RESOLVE_DEBT_ROUTE = '/resolve-debt'
export const PERSONAL_LOANS_ROUTE = '/personal-loans'
export const HOME_EQUITY_LOAN_ROUTE = '/home-equity-loan'
export const DEBT_PAYOFF_CALCULATOR_ROUTE = '/tools/debt-payoff-calculator'
export const AUTHORS_ROUTE = '/authors'
export const MXP_INFO_ROUTE = '/mxp-info'
export const MXP_DEBT_CHECKUP_ROUTE = '/debt-checkup'
export const DIRECT_MAILER_ROUTE = '/savenow?brand=achieve&section=achieve_dm'

/**
 * Returns the article page route key based on the content type
 */
export const ARTICLE_TYPE_TO_BASE_PATH = {
  [CONTENT_TYPES.ARTICLE_FULL]: LEARN_ROUTE,
  [CONTENT_TYPES.CONSUMER_BLOG_FULL]: CONSUMER_NAME_ROUTE,
  [CONTENT_TYPES.CORPORATE_BLOG_FULL]: BLOG_ROUTE,
}

/**
 * Returns the product key based on the provided route.
 *
 * @param {string} route - The route for which to get the product key.
 * @returns {string} The product key. Returns an empty string if the route does not match any product.
 */
export const getProductKeyByRoute = (route) => {
  if (route.startsWith(RESOLVE_DEBT_ROUTE)) {
    return 'achieve_dr'
  } else if (route.startsWith(PERSONAL_LOANS_ROUTE)) {
    return 'achieve_pl'
  } else if (route.startsWith(HOME_EQUITY_LOAN_ROUTE)) {
    return 'achieve_hl'
  } else {
    return ''
  }
}

/**
 * Updates or adds a query string parameter in a given URI.
 *
 * @param {string} uri - The URI to be updated.
 * @param {string} key - The query string parameter key.
 * @param {string} value - The new value for the query string parameter.
 * @returns {string} The updated URI with the new query string parameter.
 */
export function updateQueryStringParameter(uri, key, value) {
  var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
  var separator = uri.indexOf('?') !== -1 ? '&' : '?'
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2')
  } else {
    return uri + separator + key + '=' + value
  }
}
